<template></template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { hubConnect } from "@/hubs/hub";
import axios from "@axios";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  data() {
    return {
      username: "",
    };
  },
  mounted() {
    let accessToken = this.$route.params.token
    useJwt.setToken(accessToken);
    axios
      .post(
        "https://flowapi.theflowchat.com/platform/token_data",
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((response) => {
        hubConnect();
        const userInfo = response.data.userList;
        const userData = {
          id: 1,
          customId: userInfo.custom_id,
          fullName: userInfo.name,
          // eslint-disable-next-line global-require
          avatar: userInfo.avatar,
          extras: {
            eCommerceCartItemsCount: 5,
          },
          role: "admin",
          ability: [{ action: "manage", subject: "all" }],
        };

        localStorage.setItem("userData", JSON.stringify(userData));
        this.$ability.update(userData.ability);
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role));
      })
      .catch((error) => {
        this.$router.replace({ path: '/login' });
      });
  },
  computed: {},
  methods: {
    login() {},
  },
};
</script>

<style lang="scss"></style>
